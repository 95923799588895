<template>
  <div class="enterprise-detail wrapper page-wrap" v-if="enterprise">
    <a-spin :spinning="loading">
      <div class="detail-status">
        <img
          v-if="
            enterprise.enterprise_enter_status == 0 ||
            enterprise.enterprise_enter_status == 1 ||
            enterprise.enterprise_enter_status == 2 ||
            enterprise.enterprise_enter_status == 3 ||
            enterprise.enterprise_leave_status == 1
          "
          src="~@/assets/status-wait.png"
          alt=""
        />
        <img
          v-if="
            (enterprise.enterprise_enter_status == 4 &&
              enterprise.enterprise_leave_status == 0) ||
            enterprise.enterprise_leave_status == 2
          "
          src="~@/assets/status-pass.png"
          alt=""
        />
        <img
          v-if="
            enterprise.enterprise_enter_status == -1 ||
            enterprise.enterprise_enter_status == -3 ||
            enterprise.enterprise_enter_status == -4 ||
            enterprise.enterprise_leave_status == -1
          "
          src="~@/assets/status-refuse.png"
          alt=""
        />
        <div class="font-28 font-bold mt-20">
          {{ enterprise.enterprise_name }}
        </div>
        <div
          class="font-18 mt-10"
          v-if="enterprise.enterprise_leave_status == 0"
          :style="{
            color: statusMap[enterprise.enterprise_enter_status].color
          }"
        >
          {{ statusMap[enterprise.enterprise_enter_status].text }}
        </div>
        <div
          class="font-18 mt-10"
          v-else
          :style="{
            color: leaveStatusMap[enterprise.enterprise_leave_status].color
          }"
        >
          {{ leaveStatusMap[enterprise.enterprise_leave_status].text }}
        </div>
        <div
          v-if="
            enterprise.enterprise_enter_status == -1 ||
            enterprise.enterprise_enter_status == -3
          "
          class="modify-btn"
          @click="goEnterpriseEdit"
        >
          返回修改
        </div>
        <div
          v-if="enterprise.enterprise_enter_status == 1"
          class="modify-btn"
          @click="goEnterpriseEdit"
        >
          填写资料
        </div>
        <div
          v-if="enterprise.enterprise_enter_status == 3"
          class="modify-btn"
          @click="goEnterpriseEdit"
        >
          签署合同
        </div>
        <div
          v-if="enterprise.enterprise_leave_status == -1"
          class="modify-btn"
          @click="goLeaveEnterpriseEdit"
        >
          填写资料
        </div>
      </div>
      <div class="line"></div>
      <div class="flow" v-if="enterprise_flow">
        <a-steps
          progress-dot
          :current="enterprise_flow.length - 1"
          direction="vertical"
        >
          <a-step v-for="(item, index) in enterprise_flow" :key="index">
            <template #title>{{ item.flow_title }}</template>
            <template #description>
              <div>
                <div>{{ item.flow_time }}</div>
                <div>
                  <a-image
                    v-for="(img, index2) in toArr(item.flow_image)"
                    :key="index2"
                    :width="150"
                    :height="150"
                    :src="img"
                    class="flow-img"
                  />
                  <div>{{ item.flow_text }}</div>
                </div>
              </div>
            </template>
          </a-step>
        </a-steps>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { getEnterpriseData } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { encode, decode } from "js-base64";
const statusMap = {
  0: {
    text: "等待审核中",
    color: "#1276cb"
  },
  "-1": {
    text: "审核未通过",
    color: "#d63030"
  },
  1: {
    text: "待提交复审",
    color: "#1276cb"
  },
  2: {
    text: "等待复审中",
    color: "#1276cb"
  },
  "-3": {
    text: "复审未通过",
    color: "#d63030"
  },
  3: {
    text: "待签署合同",
    color: "#1276cb"
  },
  "-4": {
    text: "未合同签署",
    color: "#d63030"
  },
  4: {
    text: "已完成入驻",
    color: "#73e350"
  }
};
const leaveStatusMap = {
  1: {
    text: "退园待审核",
    color: "#1276cb"
  },
  "-1": {
    text: "退园已驳回",
    color: "#d63030"
  },
  2: {
    text: "已退园",
    color: "#9d9d9d"
  }
};
export default defineComponent({
  name: "EnterpriseFlow",
  components: {},
  setup() {
    const route = useRoute();
    const pageData = reactive({
      enterprise: null,
      enterpriseId: null,
      loading: true,
      user_id: computed(() => getUserId()),
      enterprise_flow: null
    });
    const getEnterpriseDataFun = _enterpriseId => {
      getEnterpriseData({
        enterprise_id: _enterpriseId,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.enterprise = res.data.enterprise;
            pageData.enterprise_flow = res.data.enterprise.enterprise_flow;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const toArr = str => {
      if (str) {
        return str.split(",");
      }
    };
    const goEnterpriseEdit = () => {
      let status = pageData.enterprise.enterprise_enter_status;
      let stepActive = 0;
      if (status == -1) {
        stepActive = 1;
      }
      if (status == -3 || status == 1) {
        stepActive = 2;
      }
      if (status == -4 || status == 3) {
        stepActive = 2;
      }
      url.navigateTo("/enterprise/apply", {
        t: encode(`${pageData.enterpriseId},${stepActive}`)
      });
    };
    const goLeaveEnterpriseEdit = () => {
      url.navigateTo("/enterprise/detail", {
        t: encode(`${pageData.enterpriseId}`)
      });
    };
    watch(
      () => route.query,
      val => {
        if (route.path == "/enterprise/flow") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.enterpriseId = query[0];
            getEnterpriseDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.enterprise = null;
            message.error("暂无企业入驻信息");
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      statusMap,
      toArr,
      goEnterpriseEdit,
      leaveStatusMap,
      goLeaveEnterpriseEdit
    };
  }
});
</script>
<style lang="less">
.enterprise-detail {
  padding-top: 40px;
  padding-bottom: 50px;
  box-sizing: border-box;
  .detail-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
      display: block;
      width: 154px;
    }
  }
  .line {
    height: 10px;
    background-color: #f3f5f8;
    margin: 50px 0;
  }
  .ant-steps-item-title {
    font-size: 20px;
  }
  .ant-steps-item-description {
    font-size: 18px;
  }
  .flow-img {
    width: 150px;
    height: 150px;
  }
  .ant-image {
    margin-right: 10px;
  }
  .modify-btn {
    width: 130px;
    height: 35px;
    line-height: 35px;
    background-color: #1276cb;
    border-radius: 17px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
  }
}
</style>
